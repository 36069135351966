<template>
  <div class="columns has-background-white">
    <div class="column">
      <Notification
        v-if="error"
        v-html="error"
        type="danger"
      >
        {{ error }}
      </Notification>

      <form
        ref="mc_form"
        @submit.prevent="mailchimpSubscribe"
      >
        <BaseInput
          type="email"
          v-model.trim="$v.email.$model"
          :validation="$v.email"
          name="EMAIL"
        />
        <div class="block">
          <label class="label">Send me:</label>
          <ul>
            <li class="field">
              <div class="control">
                <label for="mce-group[379635]-379635-0" class="checkbox">
                  <input type="checkbox" value="8" name="group[379635][8]" id="mce-group[379635]-379635-0" checked>
                  General Updates
                </label>
              </div>
            </li>
            <li v-if="loggedIn" class="field">
              <div class="control">
                <label for="mce-group[379635]-379635-1" class="checkbox">
                  <input type="checkbox" value="16" name="group[379635][16]" id="mce-group[379635]-379635-1" checked>
                  Members-Only News
                </label>
              </div>
            </li>
            <li v-if="loggedIn && isMerchant" class="field">
              <div class="control">
                <label for="mce-group[379635]-379635-2" class="checkbox">
                  <input type="checkbox" value="32" name="group[379635][32]" id="mce-group[379635]-379635-2" checked>
                  Updates for Businesses
                </label>
              </div>
            </li>
          </ul>
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ee5e365f5894859c759df0b69_f9c9098c46" tabindex="-1" value=""></div>
        <div class="field">
          <div class="control">
            <BaseSubmit
              label="Subscribe"
              :submitting="loading"
              :disabled="subscribed || $v.$invalid"
            />
            <button
              v-if="loggedIn"
              class="button"
              @click.prevent="go(nextPath)"
            >
              Skip
            </button>
            <button
              v-else
              class="button"
              @click.prevent="back"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="column has-background-info has-text-white" style="padding: 40px;">
      <h1 class="title has-text-white"><Brand /></h1>
      <p class="subtitle has-text-white">Be the first to know about new <Brand /> deals, merchants, markets and other exclusive updates.</p>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import jsonp from "jsonp"
import queryString from "query-string"
import Brand from "@/components/Brand.vue"
import Notification from "@/components/Notification.vue"
import BaseInput from "@/components/form/BaseInput.vue"
import BaseSubmit from "@/components/form/BaseSubmit.vue"

export default {
  components: {
    Brand,
    Notification,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      error: null,
      loading: false,
      subscribed: false,
      mcURL: "https://discoverymap.us1.list-manage.com/subscribe/post-json",
      mcUserId: "ee5e365f5894859c759df0b69",
      mcListId: "f9c9098c46",
      email: '',
    }
  },
  validations: {
    email: {
      required, email
    }
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    isMerchant: function() {
      return (this.$route.query.t == "merchant") ? true : false
    },
    nextPath: function() {
      return (this.isMerchant) ? "/o/organization-new" : "/"
    }
  },
  mounted() {
    if (this.loggedIn && this.currentUser && this.currentUser.email) {
      this.email = this.currentUser.email
    }
  },
  methods: {
    go(path) {
      this.$router.push(path)
    },
    back() {
      this.$router.go(-1)
    },
    mailchimpSubscribe() {
      if (!this.email || this.loading) {
        return
      }

      this.loading = true

      const formData = new FormData(this.$refs['mc_form'])
      const data = {
        u: this.mcUserId,
        id: this.mcListId
      }
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }
      const query = queryString.stringify(data)
      const url = `${this.mcURL}?${query}`

      jsonp(url, { param: "c" }, this.onMailchimpResponse)
    },
    onMailchimpResponse(error, data) {
      this.loading = false

      if (error) {
        this.error = error
      }
      if (data && data.result === 'error') {
        this.error = data.msg
      }

      if (!this.error) {
        this.subscribed = true
        this.go(this.nextPath)
      }
    }
  }
}
</script>
